//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Media',
    props: [
        'media',
        'user_id',
        'entity_id'
    ],
    data() {
        return {
            isFullScreen: false,
            isDeleted: false,
            videoTypes: [
                'mp4', 
                'avi',
                'mov'
            ],
            imageTypes: [
                'jpg',
                'jpeg',
                'gif',
                'png'
            ]
        }
    },
    methods: {
        /**
         * Use the current selected media as profile photo.
         */
        useAsProfilePhoto(media) {
            this.$q.dialog({
                title: 'Use On Profile',
                message: 'Do you want to use this photo on your profile?',
                cancel: true,
            }).onOk(() => {
                this.$store.dispatch('profile/updateProfilePhotoById', media.id)
                .then((r) => {
                    if (r.type == 'profile_photo_updated') {
                        this.$q.notify({
                            message:'Your profile photo has been updated.',
                            color:'positive',
                            icon: 'check_circle'
                        });
                        setTimeout(() => {
                            location.reload();
                        }, 2000);
                    } else if (r.type == 'already_in_use') {
                        this.$q.notify({
                            message:'This photo is already set on your profile.',
                            color:'primary',
                            icon: 'account_circle'
                        })
                        this.isFullScreen = false;
                    } else {
                        this.$q.notify({
                            message:'Something went wrong while setting your profile photo. Please try again.',
                            color: 'negative',
                            icon: 'error'
                        })
                        this.isFullScreen = false;
                    }
                })
            })
        },
        onImgError() {
            //
        },
        deleteMedia() {
            this.$q.dialog({
                title: 'Delete Media?',
                message: 'Are you sure that you want to delete this media?',
                cancel: 'No',
                color: 'negative',
                persistent: true,
                ok: 'Yes'
            }).onOk(() => {
                this.$http({
                    url: 'medias/' + this.media.id,
                    method: "DELETE",
                }).then((response) => {
                    if (response.data['status'] == 'media_deleted') {
                        this.isDeleted = true;
                        this.isFullScreen = false;
                        this.$q.notify({
                            color: 'positive',
                            message: "The media was deleted successfully.",
                            icon: 'check_circle'
                        });
                        this.$emit('deleted', this.media.id);
                    } else {
                        this.$q.notify({
                            color: 'negative',
                            message: "There was a problem deleting the media. Please try again.",
                            icon: 'error'
                        });
                    }
                });
            }).onOk(() => {
            }).onCancel(() => {
            }).onDismiss(() => {
            })   
        },
        isVideo(media) {
            if (this.videoTypes.includes(media.type)) {
                return true;
            }

            return false;
        },
        convertMediaToUrl(media, isThumbnail = false) {
            if (isThumbnail) {
                if (!this.isVideo(media)) {
                    return process.env.VUE_APP_APISERVER + 'gb_medias/'
                    + media.user_id + '/thumb_' + media.file_name + '.' + media.type;
                }
            }
                
            return process.env.VUE_APP_APISERVER + 'gb_medias/'
            + media.user_id + '/' + media.file_name + '.' + media.type;
        }
    }

}
